<template>
  <div class="qrcode-wrap" @click="close" v-show="isShow">
      <div class="qrcode-box">
          <img src="../assets/images/24.jpg" alt="">
          <p>{{$t('foot.cbh')}}</p>
      </div>
  </div>
</template>

<script>
export default {
  name: "qrcode",
  data() {
    return {
      isShow: false
    }
  },
  props: {
    visibility: {
        type: Boolean,
        default: false
    }
  },
  watch: {
    visibility (val) {
        this.isShow = val
    }
  },
  mounted() {
    
  },
  methods: {
      close() {
        this.isShow = false;
        this.$emit('onstart',false)
      }
  },
};
</script>
<style scoped lang="scss">
.qrcode-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0;
    text-align: center;
    line-height: 100vh;
    z-index: 100;

    .qrcode-box {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
       

        img {
            width: 280px;
            height: 280px;
        }

        p {
            font-size: 32px;
            color: #fff;
            margin-top: 50px;
        }
    }
}
</style>
