<template>
  <div class="firstpage">
    <!-- 头条 -->
    <div class="headline w">
      <div class="headline-left">
        <h5>{{$t('home.cloud.information')}}</h5>
      </div>

      <div class="headline-middle">
        <marquee
          style="height:50px;width:100%"
          onmouseover="this.stop()"
          onmouseout="this.start()"
          behavior
          direction="up"
          scrollamount="1"
        >
          <!-- <div class="contents" v-for="(item,index) in noticeData" :key="index">
            <div class="oversRight">
              <i @click="newListPorult(item.id)">{{item.title}}</i>
              <span>{{item.create_date}}</span>
              <p @click="newListPorult(item.id)">{{item.content}}</p>
            </div>
          </div> -->
          <div class="ulflex">
            <div class="contents" v-for="(item,index) in noticeData" :key="index">
              <div class="oversRight">
                <i @click="newListPorult(item.id)">{{item.title}}</i>
                <span>{{item.create_date}}</span>
                <p @click="newListPorult(item.id)">{{item.content}}</p>
              </div>
            </div>
          </div>
        </marquee>
      </div>
      <!-- <div class="headline-right">
        <marquee
          style="height:50px;"
          onmouseover="this.stop()"
          onmouseout="this.start()"
          behavior
          direction="up"
          scrollamount="1"
        >
          <div class="contents" v-for="(item,index) in noticeData" :key="index">
            <div class="oversRight" v-if="noticeData.length>2 ? index>(index/2) : index>=1">
              <i @click="newListPorult(item.id)">{{item.title}}</i>
              <span>{{item.create_date}}</span>
              <p @click="newListPorult(item.id)">{{item.content}}</p>
            </div>
          </div>
        </marquee>
      </div> -->
    </div>
    <!-- 6个图标 -->
    <div class="icon w">
      <ul>
        <li
          v-for="item in iconMenu"
          :key="item.lanKey"
          @click="item.showQr ? showQr() : iconListFn(item)"
        >
          <a href="javascript:;">
            <img :src="item.src" alt />
          </a>
          <p>{{$t('home.cloud.'+item.lanKey)}}</p>
        </li>
      </ul>
    </div>

    <!-- 云新闻 -->
    <div class="news w">
      <h5>云新闻</h5>
      <p>NEWS&nbsp;AND&nbsp;INFORMATION</p>
      <a href="javascript:;" @click="newsRouter">MORE</a>
    </div>
    <!-- 喝茶图 -->
    <div class="tea w">
      <div class="tea-left teaHoverCuoper" v-if="startShow1" @click="routeNewsList(gounpListData[0].id)">
        <div class="back">
          <p>{{gounpListData[0].brief}}</p>
        </div>
        <img
          :src="gounpListData[0].image"
          style="width:100%;height:100%"
        />
      </div>
      <div class="tea-right teaHoverCuoper">
        <div class="tea-right-1" v-if="startShow2" @click="routeNewsList(gounpListData[1].id)">
          <div class="tea-right-1-left">
            <img
              :src="gounpListData[1].image"
            />
          </div>
          <div class="tea-right-1-right">
            <h5>{{gounpListData[1].title}}</h5>
            <i>{{gounpListData[1].brief}}</i>
            <p>{{gounpListData[1].create_date}}</p>
          </div>
        </div>
        <div class="tea-right-2"></div>

        <div v-if="startShow3" class="diver" >
          <div class="tea-right-3 teaHoverCuoper" @click="routeNewsList(gounpListData[2].id)">
            <h5>{{gounpListData[2].title}}</h5>
            <i>{{gounpListData[2].create_date}}</i>

            <p>{{gounpListData[2].brief}}</p>
          </div>
          <div class="tea-right-4">
            <div class="tea-right-4-1"></div>
          </div>
        </div>

        <div v-if="startShow4" class="tea-right-5 teaHoverCuoper" @click="routeNewsList(gounpListData[3].id)">
          <h5>{{gounpListData[3].title}}</h5>
          <i>{{gounpListData[3].create_date}}</i>

          <p>{{gounpListData[3].brief}}</p>
        </div>
        
      </div>
    </div>
    <!-- 云图像 -->
    <div class="news w">
      <div class="three-top">
        <h5>云图像</h5>
        <p>CLOUD&nbsp;IMAGEI</p>
        <a href="javascript:;" @click="imgList">MORE</a>
      </div>
    </div>
    <!-- 3张图 -->
    <div class="three w">
      <ul>
        <li v-for="(items,index) in brandImgList" :key="items.id">
          <div class="divOver" @click="imgRouterPush(index)">
            <img :src="items.image" alt="" class="imgHeight">
            <!-- <el-image :src="items.image" class="imgHeight" :preview-src-list="[items.image]"></el-image> -->
          </div>
        </li>
      </ul>
    </div>
    <!-- 云视频 -->
    <div class="news video w">
      <h5>云视频</h5>
      <p>CLOUD&nbsp;VIDEO</p>
      <!-- <router-link to="/BrandPresence">MORE</router-link> -->
      <a href="javascript:;" @click="RouterJupm()">MORE</a>
    </div>
    <!-- 4张图片 -->
    <div class="four w">
      <div class="four-top" @click="overTon(cloudFirst.video_url,'1',cloudFirst)">
        <a href="javascropt:;">
          <!-- <div class="kuai">
            <div class="kuai-wrap">
              <img src="../assets/images/video.png" style="width:100%;height:100%" alt />
              <p>{{cloudFirst.title}}</p>
            </div>
          </div> -->
          <img v-if="cloudFirst" :src="cloudFirst.master_image" style="width:100%;height:100%" alt />
        </a>
      </div>
      <div class="four-bottom">
        <ul class="flexVideosList">
          <li v-for="item in cloudVideoList" :key="item.id" @click="overTon(item.id,item)">
            <a href="javascropt:;">
              <!-- <div class="kuai">
                <div class="kuai-wrap">
                  <img src="../assets/images/video.png" alt />
                  <p>{{item.title}}</p>
                </div>
              </div> -->
              <img :src="item.cover" alt />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="viersionSTART" v-if="versionStart!=='模板三'">
      <!-- 展商导航 -->
      <div class="nav w">
        <h5>展商导览</h5>
        <p class="titlesVerison">EXHIBITION&nbsp;GULDE</p>
      </div>
      <div class="nav-image">
        <div class="overs">
          <h5>{{$t('home.guide.title')}}</h5>
          <div class="xian"></div>
          <p>{{$t('home.guide.cont')}}</p>
          <!-- <div class="more"> -->
          <a class="more" href="javascript:;" @click="RouteOver">MORE</a>
          <!-- </div> -->
        </div>
        <img style="width:100%;" src="../assets/images/banner04.jpg" alt />
      </div>
    </div>


    <div v-if="versionStart==='模板三'" class="ceremonyVideo">
      <div class="videoImgBack" @click="videoServion">
        <i class="bgc"></i>
        <img src="../assets/images/video.png" class="videoImg" alt="">
        <img :src="versionData.videoInfo.videoImage" alt="">
      </div>
    </div>


    <!-- 茶文化 -->
    <div class="nav1 w">
      <h5>茶文化</h5>
      <p class="p">TEA&nbsp;CULTURE</p>
      <a class="more" href="javascript:;" @click="RouteOver(7)">MORE</a>
    </div>
    <!-- 5个图片 -->
    <div class="five w">
      <ul>
        <li v-for="item in teaCateList" :key="item.id" @click="culTureRoute(item)">
          <div>
            <img :src="item.image" alt />
            <p>{{item.name}}</p>
          </div>
        </li>
      </ul>
    </div>

    

    <div v-show="scrollStart" @click="backTop" class="top-image">
      <a href="javascript:;">
        <img style="width: 60px; height: 60px" src="../assets/images/top.png" alt />
      </a>
    </div>

    <div v-if="versionStart==='模板二' || versionStart==='模板三'" class="modelVersion">
      <!-- 品牌企业 -->
      <div class="nav1 w" style="padding-bottom:40px">
        <h5>品牌企业</h5>
        <p class="p" style="text-align:center;display:block;padding:0">BRAND ENTERPRISE</p>
      </div>
      <div class="logoImg w">
        <swiper :options="swiperOption">
        　　<swiper-slide class="slideImg" v-for="(item,index) in versionData.enterpriseList" :key="index">
              <img :src="item.logo" alt="">
            </swiper-slide>
        </swiper>
      </div>

      <!-- 合作媒体 -->
      <div class="nav1 w collaboratePadd" style="padding-bottom:40px">
        <h5>合作媒体</h5>
        <p class="p" style="text-align:center;display:block;padding:0">ASSOCIATED MEDIIA</p>
      </div>
      <div class="logoImg w">
        <swiper :options="swiperOption">
        　　<swiper-slide class="slideImg" v-for="(item,index) in versionData.mediaList" :key="index">
              <img :src="item.logo" alt="">
            </swiper-slide>
        </swiper>
      </div>
    </div>
    

    <qrcode :visibility="qrShow" @onstart="onStartInit" />
    <videos
      :dialogVisible="startVideo"
      :videos="srcVideo"
      @onstart="onstartVideo"
      :title="titleModel"
    ></videos>
  </div>
</template>

<script>
// import Swiper from "swiper";
import imgsrc1 from "@/assets/images/111.jpg";
import imgsrc2 from "@/assets/images/112.jpg";
import imgsrc3 from "@/assets/images/114.jpg";
import qrcode from "@/components/qrcode.vue";
import { getIndexData } from "@/api/index";
import {
  cloudImg,
  cloudVideo,
  getCultureCateList,
  getCloudNews,
  getCloudNotice,
  getVideoStart,
  getMediaListVideoImg,
  getCloudNoticeListData
} from "@/api/index";
import { setBannerType } from "@/utils/utils";
import videos from "@/components/videos.vue";


import 'swiper/dist/css/swiper.css'
 
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: "Index",
  data() {
    return {
      versionStart:'模板一',
      iconMenu: [
        {
          lanKey: "hall",
          src: require("../assets/images/04.png"),
          showQr: true
        },
        {
          lanKey: "signUp",
          linkKey: "pc_home_audience_sign",
          src: require("../assets/images/02.png"),
          link: ""
        },
        {
          lanKey: "exhibitor",
          linkKey: "pc_home_exhibitor_sign",
          src: require("../assets/images/05.png"),
          link: ""
        },
        {
          lanKey: "booking",
          linkKey: "pc_home_booth_book",
          src: require("../assets/images/01.png"),
          link: ""
        },
        {
          lanKey: "activity",
          linkKey: "pc_home_concurrent_events",
          src: require("../assets/images/07.png"),
          link: ""
        },
        {
          lanKey: "guide",
          linkKey: "pc_home_exhibitors_guide",
          src: require("../assets/images/06.png"),
          link: ""
        }
      ],
      mainBtnData: [], // 主转跳按钮信息
      titleModel: "详细视频",
      qrShow: false,
      url1: imgsrc1,
      url2: imgsrc2,
      url3: imgsrc3,
      page: 1,
      limit: 3,
      brandImgList: [],
      cloudFirst: {
        video_url:'',
        title:'',
        master_image:'',
        video_url:'',
        video_url:'',
      },
      cloudVideoList: [],
      teaCateList: [],
      cloudNews: [],
      scrollStart: false, // sroll
      startVideo: false, // video
      srcVideo: "", //videoSrc
      noticeData: [], // zixun
      gounpListData: [], // 云新闻
      versionData:{
        videoInfo:{
          videoImage:''
        }
      },
      swiperOption: {
    　　slidesPerView : 5,
        spaceBetween : 20,
        observer: true,
        observeParents: true,
        loop: true,
      },
      startShow4:false,
      startShow3:false,
      startShow2:false,
      startShow1:false,
    };
  },
  components: {
    qrcode,
    videos,
    Swiper,
    SwiperSlide
  },
  mounted() {
    /* eslint-disable no-new */
    sessionStorage.setItem("important_bId_custom", 1);
    this.$store.commit('setBannerId', 1);
    new Swiper('.swiper-container', {
      slidesPerView : 3,
      spaceBetween : 20,
      observer: true,
      observeParents: true,
      loop: true,
    })
    // getIndexData({
    //   page: 1,
    //   lang: "zh-cn",
    //   type: 1,
    //   categoryId: 0,
    // }).then((res) => {
    //   console.log("res", res);
    // });
    //云图片
    cloudImg({
      page: this.page,
      limit: this.limit
    }).then(res => {
      if (res.code == "200") {
        this.brandImgList = res.data.data;
      }
    });
    //云视频
    cloudVideo({
      page: this.page,
      limit: this.limit
    }).then(res => {
      if (res.code == "200") {
        this.$set(this,'cloudFirst',res.data)
        this.$set(this,'cloudVideoList',res.data.data)
      }
    });
    //茶文化
    getCultureCateList().then(res => {
      if (res.code == "200") {
        this.teaCateList = res.data;
      }
    });
    //云新闻

    // 滚动
    window.addEventListener("scroll", this.handleScroll, true);
  },
  created() {
    this.initVideos();
    // 资讯
    this.initgetCloudNotice();
    // 新闻
    this.getCloudNewsList();

    this.startInfoVideoImg();
    this.keyViersionStart();
  },
  methods: {
    newsRouter(){
      this.$router.push({
        name:'New',
        query:{
          type:'2'
        }
      })
      sessionStorage.setItem("important_bId_custom", 2);
      this.$store.commit('setBannerId', 2);
    },
    videoServion(){
      this.srcVideo = this.versionData.videoInfo.videoUrl
      this.startVideo = true;
    },
    startInfoVideoImg(){
      getMediaListVideoImg().then(res=>{
        this.versionData = res.data;
      })
    },
    keyViersionStart(){
      const datas = {
        key:'template'
      }
      getCloudNoticeListData(datas).then(res=>{
        this.versionStart = res.data.value
      })
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },
    culTureRoute(row) {
      let nav = this.$myJson(this.$store.getters.navItem({
        key: "url_link",
        value: this.mainBtnData['pc_home_tea_culture']
      }));
      if (nav.url_link) {
        nav.url_link = nav.url_link.slice(0, nav.url_link.indexOf('?type=')+6) + row.id
      }
      this.$pubNavLink(nav, this);
      // this.$router.push({
      //   name: "Tea",
      //   query: {
      //     type: row.id
      //   }
      // });
    },
    RouteOver(e) {
      if(e===7){
        this.$router.push({
          name: "Tea",
          query: {
            type: "1"
          }
        });
        sessionStorage.setItem("important_bId_custom", 7);
        this.$store.commit('setBannerId', 7);
      }else{
        let nav = this.$myJson(this.$store.getters.navItem({
          key: "url_link",
          value: this.mainBtnData['pc_home_exhibitors_guide']
        }));
        
        this.$pubNavLink(nav, this);
      }
      
      // this.$router.push({
      //   name: "Tea",
      //   query: {
      //     type: "1"
      //   }
      // });
    },
    imgList(){
      sessionStorage.setItem("important_bId_custom", 2);
      this.$store.commit('setBannerId', 2)
      this.$router.push({
        name:'New',
        query:{
          type:1
        }
      })
    },
    imgRouterPush(e){
      sessionStorage.setItem("important_bId_custom", 2);
      this.$store.commit('setBannerId', 2)
      this.$router.push({
        name:'New',
        query:{
          type:1,
          start:e+1
        }
      })
    },
    torsRouterBtn() {
      this.$router.push({
        name: "Exhibitors",
        query: {
          type: "3"
        }
      });
    },
    onStartInit(val) {
      this.qrShow = val;
    },
    RouterJupm(str) {
      // let nav = this.$myJson(this.$store.getters.navItem({
      //   key: "url_link",
      //   value: this.mainBtnData['pc_home_cloud_image']
      // }));
      // if (str && nav.url_link) {
      //   nav.url_link = nav.url_link + (nav.url_link.indexOf('?')>-1 ? '&':'?')+ 'role=' + str
      // }
      // // console.log('跳', this.$myJson(nav))
      // this.$pubNavLink(nav, this);

      // sessionStorage.setItem("important_bId_custom", 3);
      // // this.$router.push({
      // //   name: "BrandPresence",
      // //   query: {
      // //     type: str
      // //   }
      // // });
      // this.$store.commit('setBannerId', 3)
      sessionStorage.setItem("important_bId_custom", 5);
      this.$store.commit('setBannerId', 5);
      this.$router.push({
        name:'Zhibo',
        query:{
          type:'3'
        }
      })
    },
    routeNewsList(e) {
      this.$router.push({
        name: "newDetails",
        query: {
          id: e
        }
      });
    },
    getCloudNewsList() {
      getCloudNews().then(res => {
        this.gounpListData = res.data;
        res.data.length>3 ? this.startShow4 = true : this.startShow4 = false
        res.data.length>2 ? this.startShow3 = true : this.startShow3 = false
        res.data.length>1 ? this.startShow2 = true : this.startShow2 = false
        res.data.length>0 ? this.startShow1 = true : this.startShow1 = false
      });
    },
    // 点击跳转资讯详情
    newListPorult(e) {
      this.$router.push({
        name: "NewList",
        query: {
          id: e
        }
      });
    },
    initVideos() {
      // getVideoStart().then(res=>{
      //   console.log(res,11111)
      // })
    },
    initgetCloudNotice() {
      getCloudNotice().then(res => {
        // console.log("information", res);
        this.noticeData = res.data;
        this.mainBtnData = res.iconList;
        this.iconMenu.map(m => {
          // 上菜单链接
          if (m.linkKey) {
            m.link = res.iconList[m.linkKey];
          }
        });
      });
    },
    onstartVideo() {
      this.startVideo = false;
    },
    handleScroll() {
      let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置
      // console.log("看看滚动了多高的距离",scrolltop);
      if (scrolltop > window.innerHeight) {
        // 浏览器窗口的内部高度 window.innerHeight
        this.scrollStart = true; // 超过就显示出来
      } else {
        this.scrollStart = false; // 不超过还藏起来
      }
    },
    // videos
    overTon(e, str, title) {
      if (str === "1") {
        // this.srcVideo = this.cloudFirst.video_url;
        // this.startVideo = true;
        sessionStorage.setItem("important_bId_custom", 5);
        this.$store.commit('setBannerId', 5);
        this.$router.push({
          name:'Zhibo',
          query:{
            type:'3'
          }
        })
      }else if(e===10){
        sessionStorage.setItem("important_bId_custom", 0);
        this.$store.commit('setBannerId', 0);
        this.$router.push({
          name:'Zhibo',
          query:{
            type:'4'
          }
        })
      }else if(e===17){
        sessionStorage.setItem("important_bId_custom", 5);
        this.$store.commit('setBannerId', 5);
        this.$router.push({
          name:'Zhibo',
          query:{
            type:'2'
          }
        })
      }else if(e===16){
        sessionStorage.setItem("important_bId_custom", 5);
        this.$store.commit('setBannerId', 5);
        this.$router.push({
          name:'Zhibo',
          query:{
            type:'1'
          }
        })
      }
      // else {
      //   let videoFilter = this.cloudVideoList.filter(item => {
      //     return item.id === e;
      //   });
      //   this.srcVideo = videoFilter[0].video_url;
      //   this.titleModel = str.title;
      //   this.startVideo = true;
      // }
    },

    backTop() {
      // document.body.scrollTop = 0
      // document.documentElement.scrollTop = 0
      let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置
      // 定时器平滑滚动
      const time = setInterval(() => {
        document.documentElement.scrollTop = scrolltop -= 100;
        if (scrolltop <= 0) {
          // 定时器要及时清除掉，要不然一直执行很恐怖的
          clearInterval(time);
        }
      }, 0);
    },
    showQr() {
      this.qrShow = true;
    },
    iconListFn(item) {
      // 云资讯菜单点击
      // console.log("item", this.$myJson(item));
      if (item.link.indexOf("http:") > -1 || item.link.indexOf("https:") > -1) {
        window.open(item.link);
      } else {
        let nav = this.$store.getters.navItem({
          key: "url_link",
          value: item.link
        });
        this.$pubNavLink(nav, this);
        // this.$router.push({
        //   path: item.link
        // });
      }
    }
  }
};
</script>
<style scoped  lang="scss">

.ceremonyVideo{
  width: 100%;
  height:500px;
  padding-top:80px;
  .videoImgBack{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .videoImg{
      position:absolute;
      z-index:2;
      opacity:0.7
    }
    img{
      &:last-child{
        height:100%;
      }
    }
    .bgc{
      position:absolute;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.5);
      opacity: 0;
      transition: all .5s;
    }
    &:hover{
      transition: all .5s;
      .bgc{
        transition: all .5s;
        opacity: 1;
      }
      .videoImg{
        opacity: 1;
      }
    }
  }
}
.collaboratePadd{
  margin-top: 0px!important;
}
.slideImg{
  height: 178px;
  img{
    border:1px solid #ccc;
    height: 65%!important;
    width:192px;
  }
}
.swiper-container {
  width: 88%;
  height: 250px;
  cursor: pointer;
}
.three-top {
  color: #000;
  // cursor: pointer;
}
.divOver {
  position: relative;
  overflow: hidden;
  .imgHeight {
    height: 100%;
    position: relative;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: all 0.5s;
    }
  }
}
.teaHoverCuoper {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .back {
    z-index: 2;
  }
  img {
    &:hover {
      transition: all 0.5s;
      transform: scale(1.1);
    }
  }
}
.flexVideosList {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  li {
    flex: 1;
    &:hover{
      img{
        transition: all .5s;
        transform: scale(1.05);
      }
    }
  }
  img {
    height: 100%;
  }
}
marquee {
  p,
  i {
    &:hover {
      cursor: pointer;
      color: #31d65a !important;
      text-decoration: underline;
    }
  }
}
.contents {
  i {
    padding-top: 10px;
  }
  span {
    padding-top: 15px;
    font-size: 12px;
  }
}
.headline {
  height: 100px;
  // background: olive;

  .headline-left {
    float: left;
    width: 180px;
    height: 100px;
    // background: orange;
    h5 {
      font-size: 24px;
      margin-top: 60px;
      font-weight: 570;
      color: #333333;
    }
  }
  .headline-middle {
    margin-top: 40px;
    float: left;
    width: 84%;
    height: 60px;
    //  background: yellow;
    i {
      display: inline-block;
      font-weight: 600;
    }
    .ulflex{
      display:flex;
      justify-content:center;
      align-items:center;
      flex-wrap: wrap;
      .contents{
        list-style: none;
        min-width:47%;
        flex:1;
      }
    }
    span {
      display: inline-block;
      color: #b8b8b8;
      margin-left: 95px;
    }
    p {
      font-size: 14px;
      color: #999;
      margin-top: 30px;
    }
  }
  .headline-right {
    float: left;
    margin-top: 40px;
    width: 510px;
    height: 60px;
    //  background: red;
    i {
      display: inline-block;
      font-weight: 600;
    }
    span {
      display: inline-block;
      float: right;
      color: #999;
    }
    p {
      font-size: 14px;
      color: #999;
      margin-top: 30px;
    }
  }
}
.icon {
  height: 128px;
  //  background: wheat;
  margin-top: 76px;

  ul {
    display: flex;
    justify-content: center;
    li {
      float: left;
      height: 128px;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;
      }

      img {
        // margin-left: 40px;
      }
      p {
        margin-top: 12px;
        font-size: 14px;
        // margin-left: 60px;
      }
    }
  }
}

.news {
  margin-top: 66px;
  height: 60px;
  position: relative;
  //  background: violet;
  h5 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #333333;
  }
  p {
    //  display: inline-block;
    //  padding-left: 530px;
    box-sizing: border-box;
    font-size: 20px;
    color: #999;
    margin-top: 20px;
    text-align: center;
  }
  i {
    position: absolute;
    top: 45px;
    right: 3px;
    display: inline-block;
    font-size: 20px;
    color: #666666;
    padding-left: 475px;
    box-sizing: border-box;
  }
  a {
    position: absolute;
    top: 45px;
    right: 3px;
    display: inline-block;
    font-size: 20px;
    color: #666666;
    // padding-left: 475px;
    box-sizing: border-box;
    &:hover {
      text-decoration: underline;
    }
  }
}

.tea {
  margin-top: 44px;
  height: 332px;
  // background: cadetblue;
  .tea-left {
    float: left;
    width: 590px;
    height: 332px;
    position: relative;
    //  background: red;
    .back {
      position: absolute;
      top: 283px;
      height: 50px;
      width: 590px;
      background: rgba(51, 51, 51, 0.5);

      p {
        line-height: 50px;
        margin-left: 20px;
        font-weight: 400;
        color: #ffffff;
        font-size: 20px;
      }
    }

    img {
    }
  }
  .tea-right {
    float: right;
    width: 590px;
    height: 332px;
    //  background: yellow;

    .tea-right-1 {
      height: 161px;
      width: 590px;
      //  background: fuchsia;
      .tea-right-1-left {
        float: left;
        height: 170px;
        width: 270px;
        position: relative;
        overflow: hidden;
        img{
          width:100%;
        }
        //  background: peru;
      }
      .tea-right-1-right {
        float: right;
        height: 161px;
        width: 295px;
        //  background: royalblue;

        h5 {
          font-weight: 600;
          color: #333;
          line-height: 22px;
        }

        i {
          margin-top: 14px;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #666;
          line-height: 20px;
        }

        p {
          margin-top: 11px;
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          color: #999;
        }
      }
    }
    .tea-right-2 {
      height: 40px;
      width: 590px;
      //  background: palegreen;
    }
    .tea-right-3 {
      height: 48px;
      widows: 590px;
      //  background: peachpuff;

      h5 {
        display: inline-block;
        font-weight: 600;
        color: #333;
      }

      i {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        float: right;
      }

      p {
        font-size: 14px;
        color: #666;
        margin-top: 17px;
      }
    }
  }
  .tea-right-4 {
    // background: darkorange;
    height: 35px;
    width: 590px;
    .tea-right-4-1 {
      border-bottom: 1px dashed #999;
      padding-top: 17px;
    }
  }
  .tea-right-5 {
    h5 {
      display: inline-block;
      font-weight: 600;
      color: #333;
    }

    i {
      font-size: 12px;
      color: #999;
      font-weight: 400;
      float: right;
    }

    p {
      font-size: 14px;
      color: #666;
      margin-top: 15px;
    }
  }
}
.three {
  margin-top: 43px;
  // background: violet;
  height: 250px;

  ul li {
    float: left;
    margin-right: 20px;
    width: 385px;
    height: 250px;

    &:nth-child(3n) {
      margin-right: 0px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.img {
  margin: 0 20px;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.video {
  margin-top: 67px;
}
.four {
  margin-top: 40px;
  height: 670px;
  // background: lightcoral;

  .four-top {
    height: 400px;
    position: relative;
    overflow: hidden;
    &:hover {
      .kuai {
        opacity: 1;
      }
      img{
        transition: all .5s;
        transform: scale(1.05);
      }
    }
  }
  .four-bottom {
    margin-top: 20px;
    height: 250px;
    // background: lightgreen;
    ul {
      li {
        width: 385px;
        height: 250px;
        margin-right: 20px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  ul li {
    float: left;
    margin-right: 20px;
    position: relative;
    height: 250px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      .kuai {
        opacity: 1;
      }
    }
  }
}
.nav {
  margin-top: 86px;
  height: 60px;
  //  background: violet;
  h5 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #424242;
  }
  p {
    display: inline-block;
    text-align: center;
    color: #999;
    margin-top: 20px;
    font-size: 20px;
    box-sizing: border-box;
  }
  i {
    font-size: 20px;
    color: #666666;
    padding-left: 475px;
    box-sizing: border-box;
  }
}
.nav-image {
  margin-top: 60px;
  height: 550px;
  width: 100%;
  //  background: lightgreen;
  position: relative;
  .overs {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 80px;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    > * {
      margin-bottom: 30px;
    }
  }
  h5 {
    // position: absolute;
    top: 109px;
    left: 360px;
    font-weight: 500;
    color: #ffffff;
    font-size: 48px;
  }
  img {
    height: 100%;
  }

  p {
    // position: absolute;
    top: 220px;
    left: 360px;
    font-size: 20px;
    color: #fff;
  }

  .xian {
    width: 60px;
    height: 3px;
    background: #0e9b32;
  }

  .more {
    width: 182px;
    height: 60px;
    margin-top: 80px;
    background: rgba(0, 130, 0, 0.7);
    text-align: center;
    color: #ffffff;
    font-size: 26px;
    text-align: center;
    line-height: 60px;
  }
}
.five {
  margin-top: 95px;
  margin-bottom: 142px;
  height: 300px;
  position: relative;
  cursor: pointer;

  ul {
    height: 320px;
  }

  ul li {
    float: left;
    height: 320px;
    width: 230px;
    margin-right: 12px;

    div {
      position: relative;
      height: 320px;
      width: 230px;
      transform: scale(1);
      transition: all 0.3s;
      box-shadow: 0px 2px 11px 0px #ffffff;

      img {
        height: 100%;
        width: 100%;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: #fff;
        font-size: 16px;
        color: #565656;
        line-height: 50px;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        transform: scale(1.2);
        box-shadow: 0px 2px 11px 0px #e2e2e2;
        z-index: 1;

        p {
          opacity: 1;
        }
      }
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.top-image {
  position: fixed;
  right: 30px;
  bottom: 50px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.kuai {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;

  .kuai-wrap {
    text-align: center;
    p {
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      margin-top: 38px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.nav1 {
  margin-top: 86px;
  height: 60px;
  //  background: violet;
  h5 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #424242;
  }
  p {
    display: inline-block;
    text-align: center;
    color: #999;
    margin-top: 20px;
    font-size: 20px;
    padding-left: 530px;
    box-sizing: border-box;
  }
  a {
    font-size: 20px;
    color: #666666;
    padding-left: 475px;
    box-sizing: border-box;
  }
}
.titlesVerison{
  display:block!important;
  text-align:center!important;
}
</style>